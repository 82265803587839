import React from 'react';
import { IntroDiv } from '../styles/components/Intro';
import RepelAffector from '../partials/RepelAffector';
import { Link } from 'gatsby';
import ShowOnScroll from './ShowOnScroll/ShowOnScroll';

interface Link {
  href: string;
  target?: string;
  label: string;
  isExternal?: boolean;
}

const links: Link[] = [
  {
    href: "https://www.linkedin.com/in/benoit-lavoie-lamer-28410140/",
    label: "Let's Chat"
  }
]

const startYear = 2010;
const currentYear = new Date().getFullYear();
const yearsOfExperience = currentYear - startYear;

export const Intro = () => (
  <IntroDiv>
    <div className="intro">
      <ShowOnScroll>
        <h1>Benoit Lavoie-Lamer</h1>
      </ShowOnScroll>
      <ShowOnScroll delay={50}>
        <p>Senior web developer with {yearsOfExperience} years of experience, passionate about programming, problem-solving, and perfecting every detail.</p>
      </ShowOnScroll>
      <ShowOnScroll delay={100}>
        <p>Specialized in building high-performing Shopify stores that help brands generate millions in sales. I thrive on crafting seamless, engaging eCommerce experiences, with a keen focus on micro-interactions that elevate user engagement.</p>
      </ShowOnScroll>
      <ShowOnScroll delay={150}>
        <p>Committed to continuous learning, I’m always exploring new technologies and refining my workflow to deliver optimized, future-ready solutions.</p>
      </ShowOnScroll>
      <ul className="intro__items">
        {links.map(link => (
          <li key={link.label} className="intro__item">
            <ShowOnScroll delay={200}>
              <RepelAffector force={7000}>
                {link.isExternal ? (
                  <a href={link.href} className="intro__link" target="_blank">{link.label}</a>
                ) : (
                  <Link to={link.href} className="intro__link">{link.label}</Link>
                )}
              </RepelAffector>
            </ShowOnScroll>
          </li>
        ))}
      </ul>
    </div>
  </IntroDiv>
)