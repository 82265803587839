import React from 'react'
import styled from 'styled-components';

export const NoiseCanvas = styled.canvas`
position: absolute;
pointer-events: none;
left: 0;
top: 0;
width: 100%;
height: 100%;
z-index: 1;
mix-blend-mode: overlay;
`