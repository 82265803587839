import React, { Component } from 'react';
import { NoiseCanvas } from '../styles/components/NoiseCanvas';
import NoiseBG2 from './NoiseBG';

export default class Canvas extends Component {
  canvasEl: HTMLCanvasElement;
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.mouse = { x: 0, y: 0 };
    window.repelForce = 2000;

    new NoiseBG2(this.canvasEl);
  }
  render() {
    return (
      <>
        <NoiseCanvas Canvas ref={ref => this.canvasEl = ref}></NoiseCanvas>
        {this.props.children}
      </>
    )
  }
}