import * as PIXI from "pixi.js";
import { updateRepel } from "../utils/Tools";

interface Graphics extends PIXI.Graphics {
  forceY: number;
  initialX: number
  initialY: number;
  forceX: number;
}

export default class NoiseBG2 {
  app: PIXI.Application;
  container: PIXI.Container;
  width: number = window.innerWidth;
  height: number = window.innerHeight;
  cols: number = 0;
  rows: number = 0;
  size: number = 20;
  scale: number = 900;
  scaleTime: number = 4000;
  resizeTimer: number;
  repelAffectors: NodeListOf<HTMLElement> = document.querySelectorAll('[data-affect-repel]');
  defaultRepel: number = window.repelForce;
  mousedownRepel: number = 200;
  constructor(private canvas: HTMLCanvasElement) {
    this.setColsRows();

    this.app = new PIXI.Application({
      width: this.width,
      height: this.height,
      antialias: true,
      view: this.canvas,
      transparent: true,

      resizeTo: window
    });

    this.container = new PIXI.Container();
    this.app.stage.addChild(this.container);
    this.app.ticker.add(this.updateDots);
    this.createDots();
    this.bindEvents();
  }

  bindEvents(): void {

    window.addEventListener('resize', this.onResize);
    window.addEventListener('mousemove', this.onMouseMove);
    for (let i: number = 0; i < this.repelAffectors.length; i++) {
      this.repelAffectors[i].addEventListener('mouseenter', this.affectorMouseenter);
      this.repelAffectors[i].addEventListener('mouseleave', this.affectorMouseleave);
    }

    document.addEventListener('mousedown', () => updateRepel(this.mousedownRepel));
    document.addEventListener('mouseup', () => updateRepel(this.defaultRepel));
  }

  onMouseMove = ({ pageX, pageY }: MouseEvent) => {
    window.mouse = {
      x: pageX,
      y: pageY
    }
  }

  affectorMouseenter = (e: MouseEvent) => {
    const { target } = e;
    const value: number = +(target as HTMLElement).getAttribute('data-affect-repel');
    if (value)
      updateRepel(value);
  }

  affectorMouseleave = (e: MouseEvent) => updateRepel(this.defaultRepel);

  onResize = (): void => {
    // this.app.queueResize();
    this.width = window.innerWidth;
    this.height = window.innerHeight;
    this.setColsRows();

    window.clearTimeout(this.resizeTimer);
    this.resizeTimer = window.setTimeout(() => {
      this.app.stage.removeChild(this.container);
      this.container.destroy();
      this.container = new PIXI.Container();
      this.createDots();
      this.app.stage.addChild(this.container);
    }, 10);
  }

  deleteDots(): void {

  }

  setColsRows(): void {
    this.cols = Math.ceil(this.width / this.size);
    this.rows = Math.ceil(this.height / this.size);

  }

  createDots(): void {
    for (let x: number = 0; x < this.cols; x++) {
      for (let y: number = 0; y < this.rows; y++) {
        const dot: Graphics = new PIXI.Graphics() as Graphics;
        dot.moveTo(0, 0);
        dot.beginFill(0xffffff);
        dot.drawCircle(0, 0, 1);
        dot.endFill();

        this.container.addChild(dot);
        dot.x = x * this.size; ///
        dot.initialX = dot.x;
        dot.y = y * this.size;
        dot.initialY = dot.y;
        dot.forceX = 0;
        dot.forceY = 0;
      }
    }
  }

  updateDots = (): void => {
    const time = this.app.ticker.lastTime / this.scaleTime;
    for (let i: number = 0; i < this.container.children.length; i++) {
      const dot = this.container.children[i];
      let perlin = noise.simplex3(dot.initialX / this.scale, dot.initialY / this.scale, time) * 2;
      const x0 = dot.x;
      const y0 = dot.y;
      const x1 = window.mouse.x;
      const y1 = window.mouse.y;
      const repelForce = window.repelForce;
      const dx = x1 - x0;
      const dy = y1 - y0;
      const distance = Math.sqrt(dx * dx + dy * dy);

      const powerX = x0 - ((dx / distance) * repelForce) / distance;
      const powerY = y0 - ((dy / distance) * repelForce) / distance;

      dot.forceX = (dot.forceX + (dot.initialX - x0) / 2) / 2.1;
      dot.forceY = (dot.forceY + (dot.initialY - y0) / 2) / 2.2;

      dot.x = powerX + dot.forceX;
      dot.y = powerY + dot.forceY;

      // perlin = Math.abs(perlin);
      perlin = Math.max(.5, perlin);
      dot.scale.set(perlin);
    }
  };
}
