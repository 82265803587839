import styled from "styled-components";

export const IntroDiv = styled.div`
.intro {
	text-align: center;
	max-width: 640px;
	position: relative;
	z-index: 2;
  color: #eee;

	text-shadow: 0 0 100px #eee;

	h1 {
		margin: 0;
		font-weight: 300;
		font-size: 42px;
	}

	p {
		font-weight: 300;
		font-size: 18px;
		line-height: 31px;
	}

	&__items {
		margin: 0;
		padding: 0;
		display: block;
		justify-content: center;
	}

	&__item {
		display: inline-block;
		margin: 0 20px;
	}

	&__link {
    --gap: 8px;
		display: block;
    padding-bottom: 4px;
		color: #eee;
		font-weight: 200;
    position: relative;
    text-decoration: none;
    overflow: hidden;
    font-size: 14px;
    text-transform: uppercase;

    &:before, &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: currentColor;
      transition: transform .55s cubic-bezier(.55,0,.1,1);
		}

    &:before {
      transform: translateX(calc(-100% - var(--gap)));
    }

    &:hover {
      &:before {
        transform: translateX(0);
      }

      &:after {
        transform: translateX(calc(100% + var(--gap)));
      }
    }
	}
}
`;