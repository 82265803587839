import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Canvas from "../components/Canvas"
import RepelAffector from "../partials/RepelAffector"
import { Intro } from "../components/Intro"
import { Container } from "../styles/components/Container"


const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Canvas>
      <Container>
        <Intro/>
      </Container>
    </Canvas>
  </Layout>
)

export default IndexPage
